import "isomorphic-fetch";
const sessionKey = 'pearsonUser'


const SERVER = process.env.GATSBY_API_URL

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined";

// Get user data from localstorage
export const getUser = () =>
  isBrowser() && window.localStorage.getItem(sessionKey)
    ? JSON.parse(window.localStorage.getItem(sessionKey))
    : {};

// Save user data to localstorage
export const setUser = user =>
  window.localStorage.setItem(sessionKey, JSON.stringify(user));

export const handleLogin = async ({ email, password }) => {
  // Try to authenticate.
 // console.log(`email : ${email} , password : ${password}`)
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    };
    const data = await fetch(`${SERVER}/auth/`, options);
    const users = await data.json();
    console.log(users)
    //console.log(`name: ${users.user.email}`)
    //console.log(`token: ${users.token}`)
    
     setUser({
      _id: users.user._id,
      username: users.user.username,
      jwt: users.token,
      projects: users.user.projects,
      email: users.user.email
    })
    
    return true
  } catch (err) {
    // If something goes wrong with auth, return false.
    return false;
  }
};

// Return true if user is logged in. Otherwise false.
export const isLoggedIn = () => {
  const user = getUser();
  
  return !!user.email;
};

export const logout = callback => {
  setUser({});
  callback();
};

export const getExpirationDate = async () => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get  metadata by id
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const resp = await fetch(`${SERVER}/auth/expiration`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
